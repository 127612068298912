import axios from "axios";
import { Dispatch, useState } from "react";

import { Button } from "components/design-system/Button";
import env from "utils/processEnv";

import BackButton from "./BackButton";
import { Action } from "./Reducer";

type Props = {
  dispatch: Dispatch<Action>;
  email: string;
};
const ResendEmail = ({ dispatch, email }: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string>("");

  const handleResendVerification = () => {
    setSubmitting(true);
    axios
      .post(`${env.glueApiUrl}/auth/start`, {
        address: email,
      })
      .then(() =>
        dispatch({
          type: "VerificationCode",
          email,
        })
      )
      .catch(error => {
        console.info(error);
        setError("Something went wrong. Please try again.");
        setSubmitting(false);
      });
  };

  return (
    <div className="flex flex-col md:justify-center mx-auto max-w-full w-400 pt-0 md:pt-[68px] text-left">
      <div className="flex flex-col mb-32">
        <span className="text-title-3">Didn't get the email?</span>
        <span className="text-headline text-text-secondary">Check these common issues:</span>
        <br />
        <ul className="list-disc list-inside [&>li]:pl-10 text-headline">
          <li>Your spam folder and email filter settings</li>
          <li>Your internet connection</li>
          <li>
            Typos in your email (we sent it to{" "}
            <strong className="text-headline-bold">{email}</strong>)
          </li>
          <li>If your inbox is full</li>
        </ul>
        <br />
        <p className="m-0 text-headline">
          If these steps didn't work, resend the email. Codes in older emails will be deactivated.
        </p>
        {error && <p className="m-0 mt-16 text-sm text-accent-error">{error}</p>}
      </div>

      <div className="grow" />
      <div className="mt-[38px] mb-32 md:mb-0 flex items-center justify-between">
        <BackButton
          disabled={submitting}
          onClick={() => dispatch({ type: "VerificationCode", email })}
        />
        <Button onClick={handleResendVerification} type="button">
          Resend Email
        </Button>
      </div>
    </div>
  );
};

export default ResendEmail;
