import { WorkspaceEdgeSimple } from "@utility-types";
import Avatar from "components/design-system/Avatar/Avatar";
import { Dropdown } from "components/design-system/FloatingUi";
import { DropdownActionButton } from "components/design-system/FloatingUi/DropdownActionButton";
import { DropdownActionButtonGroup } from "components/design-system/FloatingUi/DropdownActionButtonGroup";
import { DropdownActions } from "components/design-system/FloatingUi/DropdownActions";
import PrimaryPill from "components/design-system/PrimaryPill";
import { SettingsModal } from "components/SettingsModal";
import WorkspaceModalJoin from "components/workspace/WorkspaceModal/WorkspaceModalJoin";
import { MemberRole } from "generated/graphql-types";
import useAuthData from "hooks/useAuthData";
import { usePrimaryWorkspaceEdge } from "hooks/usePrimaryWorkspaceEdge";
import useModalStore from "store/useModalStore";
import { isNative } from "utils/platform";
const WorkspaceMenu = () => {
  const { authData } = useAuthData();
  const { openModal } = useModalStore(({ openModal }) => ({ openModal }));

  const primaryWorkspace = usePrimaryWorkspaceEdge()?.node;

  const openWorkspaceSettings = async (edge: WorkspaceEdgeSimple) => {
    openModal(
      <SettingsModal
        subView={edge.memberRole === MemberRole.Admin ? "settings" : undefined}
        view={edge.node.id}
      />
    );
  };

  return (
    <Dropdown
      content={
        <DropdownActions>
          {authData?.workspaces.edges.length ? (
            <DropdownActionButtonGroup>
              <span className="px-16 text-footnote-bold text-text-subtle h-24">
                Workspace settings
              </span>
              {authData?.workspaces.edges.map((w, i) => (
                <DropdownActionButton key={w.node.id} onClick={() => openWorkspaceSettings(w)}>
                  <Avatar avatarURL={w.node.avatarURL} name={w.node.name} size="small" />
                  {w.node.name}
                  {i === 0 ? <PrimaryPill /> : null}
                </DropdownActionButton>
              ))}
            </DropdownActionButtonGroup>
          ) : null}

          <DropdownActionButton
            className="my-8"
            icon="Plus"
            onClick={() => {
              openModal(<WorkspaceModalJoin />);
            }}
          >
            {isNative() ? "Join a workspace" : "Add workspace"}
          </DropdownActionButton>
        </DropdownActions>
      }
      placement="bottom-end"
    >
      <div className="flex items-center justify-center h-44 w-40 md:h-28 md:w-28 hover:cursor-pointer">
        <div className="w-24 h-24 rounded-md overflow-hidden">
          <Avatar
            avatarURL={primaryWorkspace?.avatarURL}
            name={primaryWorkspace?.name}
            className="text-icon-secondary"
            iconProps={{ icon: "Workspace" }}
            rounded="rounded-md"
            size="small"
          />
        </div>
      </div>
    </Dropdown>
  );
};

export default WorkspaceMenu;
