import { RefObject, forwardRef } from "react";
import { useHistory } from "react-router";

import { Message } from "@utility-types";
import Avatar from "components/design-system/Avatar/Avatar";
import Hr from "components/design-system/Hr";
import { LoadingSpinner } from "components/design-system/LoadingSpinner";
import { routePath } from "components/routing/utils";
import useAppStateStore from "store/useAppStateStore";
import useSearchStore from "store/useSearchStore";
import tw from "utils/tw";

import { useInstantSearch } from "../hooks";
import { ResultEdgeType } from "../types";

import MoreResults from "./MoreResults";
import RecipientsResults from "./RecipientsResults";
import ThreadResults from "./ThreadResults";

export type SelectedSearchResult =
  | ResultEdgeType<"users">["node"]
  | ResultEdgeType<"groups">["node"]
  | ResultEdgeType<"threads">["node"]
  | Message;

type Props = {
  className?: string;
  disableResults?: boolean;
  onClickResult?: (
    item: SelectedSearchResult,
    index: number,
    event: React.MouseEvent<HTMLDivElement | HTMLLIElement, MouseEvent>
  ) => void;
  openDeepSearch?: boolean;
  searchResults: ReturnType<typeof useInstantSearch>["searchResults"];
  searchTerm?: string;
  selectedResultID?: string;
  selectedResultRef?: RefObject<HTMLLIElement>;
  setSelectedResultID?: (id: string) => void;
};

const LoadingHint = ({ searching }: { searching: boolean }) => (
  <div className="hidden md:flex items-center justify-start h-32 my-4 px-16 text-footnote italic text-text-subtle bg-background-body">
    {searching ? (
      <>
        <LoadingSpinner className="w-20 h-20 mr-8 text-icon-secondary animate-spin transition-opacity opacity-100" />
        Loading more results...
      </>
    ) : (
      <>Keep typing to refine your results...</>
    )}
  </div>
);

const DeepSearchShortcut = ({
  searchTerm,
  selectedResultID,
  selectedResultRef,
  setSelectedResultID,
}: Omit<Props, "searchResults">) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  const { addSearchHistory } = useSearchStore(({ addSearchHistory }) => ({
    addSearchHistory,
  }));

  const history = useHistory();
  const isSelected = selectedResultID === "deep-search-shortcut";

  return (
    <>
      <ul className="list">
        <li ref={isSelected ? selectedResultRef : undefined}>
          <div
            className={tw(
              "flex items-center justify-start px-16 py-8",
              "cursor-pointer select-none text-body-bold",
              {
                "!bg-accent-highlight/25": isSelected,
              }
            )}
            onClick={() => {
              searchTerm && addSearchHistory(searchTerm);
              history.push(routePath({ superTab: "search" }));
            }}
            onFocus={() => setSelectedResultID?.("deep-search-shortcut")}
            onMouseEnter={() => setSelectedResultID?.("deep-search-shortcut")}
          >
            <Avatar
              background="transparent"
              className="text-icon-secondary"
              iconProps={{ icon: "Search" }}
              margin="mr-8"
              size={breakpointMD ? "x-small" : "small"}
            />
            {searchTerm}
          </div>
        </li>
      </ul>
      <Hr className="grow mx-16 my-8" />
    </>
  );
};

const SearchResults = forwardRef<HTMLDivElement, WithChildren<Props>>(
  (
    {
      children,
      className,
      disableResults = false,
      onClickResult,
      openDeepSearch,
      searchResults,
      searchTerm,
      selectedResultID,
      selectedResultRef,
      setSelectedResultID,
    }: WithChildren<Props>,
    ref
  ): JSX.Element => {
    return (
      <div
        ref={ref}
        className={tw(
          "search-results",
          "md:max-h-[calc(100vh_-_115px)]",
          "overflow-y-scroll overscroll-contain relative",
          className
        )}
      >
        {searchResults.isReversed && (
          <>
            <LoadingHint searching={searchResults.searching} />
            <MoreResults
              disableResults={disableResults}
              isReversed={true}
              onClickResult={onClickResult}
              results={searchResults.moreResults}
              searching={searchResults.searching}
              selectedResultID={selectedResultID}
              selectedResultRef={selectedResultRef}
              setSelectedResultID={setSelectedResultID}
            />
          </>
        )}

        {openDeepSearch && (
          <DeepSearchShortcut
            searchTerm={searchTerm}
            selectedResultID={selectedResultID}
            selectedResultRef={selectedResultRef}
            setSelectedResultID={setSelectedResultID}
          />
        )}

        {searchResults.instantResults.map(results => {
          switch (results.resultType) {
            case "threads":
              return (
                <ThreadResults
                  key={results.resultType}
                  isReversed={searchResults.isReversed}
                  onClickResult={onClickResult}
                  results={results}
                  searching={searchResults.searching}
                  selectedResultID={selectedResultID}
                  selectedResultRef={selectedResultRef}
                  setSelectedResultID={setSelectedResultID}
                />
              );

            case "groups":
              return (
                <RecipientsResults
                  key={results.resultType}
                  disableResults={disableResults}
                  isReversed={searchResults.isReversed}
                  label="Groups"
                  onClickResult={onClickResult}
                  results={results}
                  searching={searchResults.searching}
                  selectedResultID={selectedResultID}
                  selectedResultRef={selectedResultRef}
                  setSelectedResultID={setSelectedResultID}
                />
              );

            case "archivedGroups":
              return null;

            default:
              return (
                <RecipientsResults
                  key={results.resultType}
                  isReversed={searchResults.isReversed}
                  label="People"
                  onClickResult={onClickResult}
                  results={results}
                  searching={searchResults.searching}
                  selectedResultID={selectedResultID}
                  selectedResultRef={selectedResultRef}
                  setSelectedResultID={setSelectedResultID}
                />
              );
          }
        })}

        {children /** used for iOS Contacts in `RecipientsMenu` component */}

        {!searchResults.isReversed && (
          <>
            <MoreResults
              disableResults={disableResults}
              isReversed={false}
              onClickResult={onClickResult}
              results={searchResults.moreResults}
              searching={searchResults.searching}
              selectedResultID={selectedResultID}
              selectedResultRef={selectedResultRef}
              setSelectedResultID={setSelectedResultID}
            />
            <LoadingHint searching={searchResults.searching} />
          </>
        )}
      </div>
    );
  }
);

SearchResults.displayName = "SearchResults";
export default SearchResults;
