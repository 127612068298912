import { useState } from "react";

import Avatar from "components/design-system/Avatar/Avatar";
import { Dropdown } from "components/design-system/FloatingUi";
import { DropdownActionButton } from "components/design-system/FloatingUi/DropdownActionButton";
import { DropdownActionButtonGroup } from "components/design-system/FloatingUi/DropdownActionButtonGroup";
import { DropdownActions } from "components/design-system/FloatingUi/DropdownActions";
import { SettingsModal } from "components/SettingsModal";
import useAuthData from "hooks/useAuthData";
import useShortcut from "hooks/useShortcut";
import useAppStateStore from "store/useAppStateStore";
import useModalStore from "store/useModalStore";
import generateRandomId from "utils/generateRandomId";
import { isNativeMac } from "utils/platform";
import env from "utils/processEnv";
import tw from "utils/tw";

import {
  AskGlueAIButton,
  ContactSupportButton,
  HelpDocsButton,
  ReleaseNotesButton,
} from "./HelpMenu";

const prefShortcut = isNativeMac() ? ["Meta", ","] : ["Control", "Meta", ","];

export const ProfileMenu = (): JSX.Element => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const { authData, signOut } = useAuthData();
  const [prefModalId] = useState(generateRandomId("preferences"));

  const { closeModal, modalIsOpen, openModal } = useModalStore(
    ({ closeModal, modalIsOpen, openModal }) => ({
      closeModal,
      modalIsOpen,
      openModal,
    })
  );

  useShortcut(prefShortcut, () => {
    if (modalIsOpen(prefModalId)) return closeModal(prefModalId);
    openModal(<SettingsModal view="preferences" />, {
      id: prefModalId,
    });
  });

  return (
    <Dropdown
      content={
        <DropdownActions>
          <DropdownActionButtonGroup>
            <DropdownActionButton
              icon="User"
              onClick={() => {
                openModal(<SettingsModal view="profile" />, {
                  id: prefModalId,
                });
              }}
            >
              Edit profile
            </DropdownActionButton>
            <DropdownActionButton
              icon="Tool"
              onClick={() => {
                openModal(<SettingsModal view="preferences" />, {
                  id: prefModalId,
                });
              }}
            >
              Preferences
            </DropdownActionButton>
            {env.glueEnv !== "production" && (
              <DropdownActionButton
                icon="Code"
                onClick={() => {
                  openModal(<SettingsModal view="feature-flags" />);
                }}
              >
                Developer
              </DropdownActionButton>
            )}
          </DropdownActionButtonGroup>

          {!breakpointMD && (
            <DropdownActionButtonGroup>
              <HelpDocsButton />
              <ReleaseNotesButton />
              <AskGlueAIButton />
              <ContactSupportButton />
            </DropdownActionButtonGroup>
          )}

          <DropdownActionButtonGroup>
            <DropdownActionButton icon="Leave" onClick={signOut}>
              Sign out
            </DropdownActionButton>
          </DropdownActionButtonGroup>
        </DropdownActions>
      }
      disabled={!authData?.me}
      placement="bottom-end"
      disableFlip
    >
      <div className="flex items-center justify-center h-44 w-40 md:h-28 md:w-28">
        <div
          className={tw(
            "cursor-pointer focus-visible-border focus-visible-shadow overflow-hidden flex shrink-0 rounded-md",
            { "cursor-default": !authData?.me }
          )}
          tabIndex={0}
        >
          <Avatar rounded="rounded-md" size="small" {...authData?.me} />
        </div>
      </div>
    </Dropdown>
  );
};
