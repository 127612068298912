import { useHistory } from "react-router-dom";

import { GlueFile, LinksCategory, nodeIs } from "@utility-types";
import { Button } from "components/design-system/Button";
import PhotoSwipeGallery, {
  defaultPhotoSwipeGalleryOptions,
} from "components/PhotoSwipe/PhotoSwipeGallery";
import { GroupTabs, tabPath } from "components/routing/utils";
import { Skeleton } from "components/Skeleton";
import { cloneElementForSkeletons } from "components/Skeleton/Skeleton";
import { useLinksQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import tw from "utils/tw";

import { Figure } from "./Media";
import styles from "./Media.module.css";
import { useSharedFilterState } from "./providers/SharedFilterProvider";
import { SharedFilters } from "./types";

const recentlySharedLimit = 6;
const RecentlySharedMedia = ({ groupID }: { groupID: string }) => {
  const { authReady } = useAuthData();
  const history = useHistory();
  const { setState: setSharedFilterState } = useSharedFilterState();

  const { data } = useLinksQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    variables: {
      filter: {
        recipientIDs: [groupID],
        linkedCategories: [LinksCategory.Media],
      },
      last: recentlySharedLimit - 1,
    },
  });

  const media = data?.links.edges
    .map(e => e.node.linked)
    .filter((i): i is GlueFile => nodeIs(i, ["File"]))
    .reverse()
    .slice(0, recentlySharedLimit);

  if (media?.length === 0) return null;

  return (
    <div className="bg-background-body rounded-lg shadow-level1 min-w-[230px]">
      <div className="flex items-center justify-between px-16 pt-12 pb-8 text-footnote-bold select-none">
        Recent media
        <Button
          buttonStyle="icon-secondary"
          buttonType="icon"
          icon="ArrowRight"
          iconSize={16}
          onClick={() => {
            setSharedFilterState({ filter: SharedFilters.Media });
            history.push(tabPath(GroupTabs.Shared));
          }}
        />
      </div>
      <PhotoSwipeGallery id="Media" options={defaultPhotoSwipeGalleryOptions}>
        <div className={tw(styles["media-grid-container"], "px-16")}>
          <div className={tw(styles["media-grid"], styles.small)}>
            {media
              ? media.map((file, index) => (
                  // Using index as temporary workaround while waiting for API to return unique results
                  <Figure key={`${file.id}-${index}`} file={file} />
                ))
              : cloneElementForSkeletons(
                  <Skeleton height="64px" width="64px" />,
                  recentlySharedLimit
                )}
          </div>
        </div>
      </PhotoSwipeGallery>
    </div>
  );
};

export default RecentlySharedMedia;
