import CalendarAvatar from "assets/apps/calendar-avatar.png";
import DriveAvatar from "assets/apps/drive-avatar.png";
import GithubAvatar from "assets/apps/github-avatar.png";
import WebhookAvatar from "assets/apps/webhook-avatar.png";
import { Button } from "components/design-system/Button";

export const AddButton = () => (
  <Button buttonStyle="simplePrimary" icon="Plus">
    Add
  </Button>
);

export const newWebhookItem = ({ onClick }: { onClick: () => void }) => [
  {
    actions: <AddButton />,
    description: "Post a message using a Slack-compatible webhook.",
    icon: { alt: "App webhook Avatar", src: WebhookAvatar },
    id: "newWebhookItem-1",
    onClick: onClick,
    title: "Webhook",
  },
];

export const comingSoonItems = [
  {
    description: "Enrich private links and be notified about issue and PR updates",
    icon: { alt: "Github Avatar", src: GithubAvatar },
    id: "comingSoonItem-1",
    title: "Github",
  },
  {
    description: "Preview, send, and be notified about Google Drive files",
    icon: { alt: "Google Drive Avatar", src: DriveAvatar },
    id: "comingSoonItem-2",
    title: "Google Drive",
  },
  {
    description: "Create events, receive updates and reminders",
    icon: { alt: "Google Calendar Avatar", src: CalendarAvatar },
    id: "comingSoonItem-3",
    title: "Google Calendar",
  },
  { id: "comingSoonEmptyItem-1" },
];
