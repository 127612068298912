import { ComponentProps, useCallback } from "react";

import { Button } from "components/design-system/Button";

import buttonStyles from "../../buttonStyles";

type BlockTypes = "toggleBlockquote" | "toggleCodeBlock" | "toggleBulletList";

type Props = ComponentProps<typeof Button> & {
  active: boolean;
  commands: (method: BlockTypes, active: boolean) => void;
  disabled: boolean;
  toggle: BlockTypes;
};

const preventDefault = (event: React.MouseEvent) => event.preventDefault();

export const BlockButton = ({
  active,
  commands,
  disabled,
  icon,
  toggle,
  tooltip,
}: Props): JSX.Element => (
  <Button
    buttonStyle="subtle"
    className={buttonStyles(active)}
    disabled={disabled}
    icon={icon}
    iconSize={20}
    onClick={useCallback(() => commands(toggle, active), [active, commands, toggle])}
    onMouseDown={preventDefault}
    tooltip={tooltip}
  />
);

export default BlockButton;
