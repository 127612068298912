import { Button } from "components/design-system/Button";
import { Form, SubmitButton } from "components/design-system/Forms";
import { Footer, Header, Main } from "components/ModalKit";
import { ModalProps } from "components/ModalKit/Modal";
import { StandardModal } from "components/Modals";
import useModalStore from "store/useModalStore";

import { ConfirmationDialog } from "./blockTypes";

interface BlockConfirmProps {
  confirm: ConfirmationDialog;
  onConfirm: () => void;
  onDeny?: () => void;
}

const BlockConfirm = (props: ModalProps & BlockConfirmProps) => {
  const { closeModal } = useModalStore(({ closeModal, openModal }) => ({
    closeModal,
    openModal,
  }));

  const onConfirm = () => {
    props.onConfirm();
    closeModal(props.modalId);
  };
  const onDeny = () => {
    if (props.onDeny) {
      props.onDeny();
    }
    closeModal(props.modalId);
  };
  return (
    <Form className="contents" onSubmit={onConfirm}>
      <StandardModal
        contentHandlesSafeArea={false}
        header={
          <Header mobileCtaLabel={props.confirm.confirm.text} variant="bordered">
            <h3 className="m-0">{props.confirm.title.text}</h3>
          </Header>
        }
        {...props}
      >
        <Main className="flex flex-col grow px-16 py-8 md:px-32">{props.confirm.text.text}</Main>

        <Footer className="hidden md:flex">
          <div className="flex space-x-16">
            <Button buttonStyle="simpleSecondary" buttonType="none" onClick={onDeny} type="button">
              {props.confirm.deny.text || "Close"}
            </Button>
            <SubmitButton requireChanges={false}>{props.confirm.confirm.text}</SubmitButton>
          </div>
        </Footer>
      </StandardModal>
    </Form>
  );
};

export default BlockConfirm;
