import { useActive, useChainedCommands, useCommands, useEditorEvent } from "@remirror/react";
import { useCallback, useRef, useState } from "react";
import { environment } from "remirror";

import { SlideInOut } from "components/Animated";
import { HorizontalScrollingList } from "components/HorizontalScrollingList";
import { QuoteReply } from "components/Icons";
import { useOnClickInsideEditor, useOnClickOutsideEditor } from "components/MessageEditor/hooks";
import { Styles } from "components/MessageElements";
import useLocalSettingsStore from "store/useLocalSettingsStore";
import tw from "utils/tw";

import { GlueWysiwygPreset } from "../../../remirror-extensions/GlueWysiwyg";
import LinkButton from "../../LinkSettings/LinkButton";
import { BlockButton } from "../BlockButton";
import { MarkButton } from "../MarkButton";

type Props = {
  show: boolean;
  showAllButtons: boolean;
};

const FormattingBar = ({ show, showAllButtons }: Props): JSX.Element => {
  const { toggleBlockquote, toggleBold, toggleBulletList, toggleItalic, updateCodeBlock } =
    useCommands<GlueWysiwygPreset>();
  const chain = useChainedCommands<GlueWysiwygPreset>();
  const active = useActive<GlueWysiwygPreset>(true);

  const getLocalSettingsState = useLocalSettingsStore.getState;

  const commandsRef = useRef({
    toggleBlockquote,
    toggleBold,
    toggleBulletList,
    toggleCodeBlock: (active?: boolean) => {
      const language = getLocalSettingsState().codeblockLanguageSetting;
      // Patch can't toggle from codeBlock to paragraph
      // class attribute persists on paragraph node so we remove them before the toggle
      if (active) {
        updateCodeBlock({
          class: Styles.paragraph,
          language,
        });
      }

      chain
        .toggleCodeBlock({
          language,
        })
        .formatCodeBlock()
        .run();
    },
    toggleItalic,
  });

  const commands = useCallback(
    (
      method:
        | "toggleBlockquote"
        | "toggleBold"
        | "toggleBulletList"
        | "toggleCodeBlock"
        | "toggleItalic",
      active?: boolean
    ) => {
      if (method === "toggleCodeBlock") {
        commandsRef.current[method](active);
        return;
      }
      commandsRef.current[method]();
    },
    []
  );

  const [disabled, setDisabled] = useState(true);

  useOnClickOutsideEditor(() => setDisabled(true));

  // keeping this so buttons get enabled when we focus the editor with the `focusEditor` method
  useEditorEvent("focus", () => setDisabled(false));

  useOnClickInsideEditor(
    ({ target }) =>
      target instanceof HTMLElement &&
      // focus only if editor text input is clicked
      target.closest("[contenteditable='true']") &&
      setDisabled(false)
  );

  const altChar = environment.isMac ? "⌘" : "Ctrl";

  return (
    <SlideInOut
      className={tw("overflow-hidden", { "mx-3": show }, { "!ml-3": showAllButtons })}
      enter={{ opacity: "1", x: "0" }}
      from={{ opacity: "0", x: showAllButtons ? "0" : "-100%" }}
      leave={{ opacity: "0", x: "-100%" }}
      show={showAllButtons || show}
    >
      <HorizontalScrollingList itemWidth={28} scrollSnapType="none" showTail={false}>
        <MarkButton
          key="strong"
          active={active.bold()}
          /* istanbul ignore next */
          commands={commands}
          disabled={disabled}
          icon="Bold"
          toggle="toggleBold"
          tooltip={`Bold - ${altChar} B`}
        />
        <MarkButton
          key="italic"
          active={active.italic()}
          commands={commands}
          /* istanbul ignore next */
          disabled={disabled}
          icon="Italic"
          toggle="toggleItalic"
          tooltip={`Italic - ${altChar} I`}
        />
        <BlockButton
          key="blockquote"
          active={active.blockquote()}
          commands={commands}
          /* istanbul ignore next */
          disabled={disabled}
          icon={QuoteReply}
          toggle="toggleBlockquote"
          tooltip="Blockquote"
        />
        <BlockButton
          key="codeBlock"
          active={active.codeBlock()}
          commands={commands}
          /* istanbul ignore next */
          disabled={disabled}
          icon="Code"
          toggle={"toggleCodeBlock"}
          tooltip="Code"
        />
        <LinkButton
          key="link"
          /* istanbul ignore next */
          disabled={disabled}
          icon="Link"
          tooltip="Link"
        />
        <BlockButton
          key="bulletList"
          active={active.bulletList()}
          commands={commands}
          /* istanbul ignore next */
          disabled={disabled}
          icon="BulletedList"
          toggle="toggleBulletList"
          tooltip="Bulleted list"
        />
      </HorizontalScrollingList>
    </SlideInOut>
  );
};

export default FormattingBar;
