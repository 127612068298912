import { ComponentProps, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { nodeAs, Workspace } from "@utility-types";
import Avatar from "components/design-system/Avatar/Avatar";
import { Icon } from "components/design-system/icons";
import { AccountSettingsView, WorkspaceSettingsView } from "components/SettingsModal/types";
import MembersSearch from "components/workspace-group/MembersSearch";
import { MemberRole } from "generated/graphql";
import { useFetchWorkspaceOrPreviewEdgeQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useMemberEdge from "hooks/useMemberEdge";
import useAppStateStore from "store/useAppStateStore";
import { isNative } from "utils/platform";
import tw from "utils/tw";

import { SidebarItem } from "../Sidebar";
import View from "../View";
import ViewContent from "../ViewContent";

import Advanced from "./Advanced";
import Apps from "./Apps";
import Billing from "./Billing";
import Groups from "./Groups";
import Members from "./Members";
import ProfileRow from "./ProfileRow";
import SettingsForm from "./SettingsForm";

const MobileHeader = ({ title, workspace }: { title: React.ReactNode; workspace: Workspace }) => (
  <div className="flex flex-col items-center">
    <div>{title}</div>
    <div className="flex gap-6 items-center justify-center w-full">
      <Avatar avatarURL={workspace?.avatarURL} name={workspace?.name} size="tiny" />
      <div className="text-footnote text-text-subtle truncate">{workspace?.name}</div>
    </div>
  </div>
);

const WorkspaceViewContent = (
  props: ComponentProps<typeof ViewContent<WorkspaceSettingsView>> & {
    workspace: Workspace;
  }
) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  return (
    <ViewContent<WorkspaceSettingsView>
      {...props}
      title={
        breakpointMD ? (
          props.title
        ) : (
          <MobileHeader title={props.title} workspace={props.workspace} />
        )
      }
    />
  );
};

const WorkspaceSettings = ({
  modalId,
  parentView: workspaceID,
  resetInitialSubView,
  setParentView,
  subView,
}: {
  modalId?: string;
  parentView?: AccountSettingsView;
  resetInitialSubView: () => void;
  setParentView: React.Dispatch<React.SetStateAction<AccountSettingsView | undefined>>;
  subView?: WorkspaceSettingsView;
}) => {
  const { authData, authReady } = useAuthData();
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const { data, error, fetchMore, loading, refetch } = useFetchWorkspaceOrPreviewEdgeQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    skip: !(workspaceID && authData),
    variables: {
      id: `${workspaceID}-${authData?.me.id}`,
    },
  });

  const { memberEdge } = useMemberEdge(
    nodeAs(data?.node, ["WorkspaceEdge", "WorkspacePreviewEdge"])
  );

  const { defaultView, isAdmin, allowBilling } = useMemo(() => {
    if (!memberEdge) return { defaultView: undefined, isAdmin: false, allowBilling: false };

    const isAdmin = memberEdge?.memberRole === MemberRole.Admin;
    const allowBilling = isAdmin && breakpointMD && !isNative();

    const defaultView: WorkspaceSettingsView | undefined = breakpointMD ? "members" : undefined;

    if (!subView) return { defaultView, isAdmin, allowBilling };

    const unrestrictedViews = ["members", "groups", "apps"];
    if (unrestrictedViews.includes(subView)) {
      return { defaultView: subView, isAdmin, allowBilling };
    }

    const restrictedViews = ["settings", "advanced"];
    if (restrictedViews.includes(subView) && isAdmin) {
      return { defaultView: subView, isAdmin, allowBilling };
    }

    if (subView === "billing" && allowBilling) {
      return { defaultView: subView, isAdmin, allowBilling };
    }

    return { defaultView, isAdmin, allowBilling };
  }, [breakpointMD, memberEdge, subView]);

  const [view, setView] = useState<WorkspaceSettingsView | undefined>(defaultView);

  // if the view is not set, set it to the default view when resizing the modal to desktop size
  useEffect(() => {
    if (!breakpointMD || view) return;
    setView(defaultView);
  }, [breakpointMD, defaultView, view]);

  // for Members view
  const [searchQuery, setSearchQuery] = useState<string | undefined>();
  const [searchLoading, setSearchLoading] = useState(false);

  const onMemberSearch = useCallback(
    (search?: string) => {
      if (search === searchQuery) return;

      setSearchQuery(search);
      if (search === undefined) return;
      !!search.length && setSearchLoading(true);
      refetch?.({ membersFilter: { match: search } }).finally(() => {
        setSearchLoading(false);
      });
    },
    [refetch, searchQuery]
  );

  return (
    <View
      main={
        view && (
          <>
            {view === "members" && memberEdge && (
              <WorkspaceViewContent
                resetInitialSubView={resetInitialSubView}
                setView={setView}
                title="Members"
                workspace={memberEdge.node}
              >
                <div className="flex flex-col h-full pb-[env(safe-area-inset-bottom)]">
                  <div className="px-20 md:px-32">
                    <MembersSearch onMemberSearch={onMemberSearch} searchQuery={searchQuery} />
                  </div>
                  <Members
                    scrollContainerRef={scrollContainerRef}
                    fetchMore={fetchMore}
                    hasError={!!error}
                    isAdmin={isAdmin}
                    modalId={modalId}
                    workspace={memberEdge.node}
                    searchLoading={searchLoading}
                    searchQuery={searchQuery}
                    loading={loading}
                  />
                </div>
              </WorkspaceViewContent>
            )}

            {view === "groups" && memberEdge && (
              <WorkspaceViewContent
                resetInitialSubView={resetInitialSubView}
                setView={setView}
                title="Groups"
                workspace={memberEdge.node}
              >
                <div className="h-full pb-[env(safe-area-inset-bottom)]">
                  <Groups
                    scrollContainerRef={scrollContainerRef}
                    fetchMore={fetchMore}
                    hasError={!!error}
                    workspace={memberEdge.node}
                    loading={loading}
                  />
                </div>
              </WorkspaceViewContent>
            )}

            {view === "apps" && memberEdge && (
              <WorkspaceViewContent
                resetInitialSubView={resetInitialSubView}
                setView={setView}
                title="Apps"
                workspace={memberEdge.node}
              >
                <div className="h-full overflow-y-auto px-20 md:px-32 pb-[env(safe-area-inset-bottom)]">
                  <Apps workspaceEdge={memberEdge} />
                </div>
              </WorkspaceViewContent>
            )}

            {view === "settings" && memberEdge && isAdmin && (
              <WorkspaceViewContent
                resetInitialSubView={resetInitialSubView}
                setView={setView}
                title="Settings"
                workspace={memberEdge.node}
              >
                <SettingsForm loading={loading} workspaceEdge={memberEdge} />
              </WorkspaceViewContent>
            )}

            {view === "advanced" && memberEdge && isAdmin && (
              <WorkspaceViewContent
                resetInitialSubView={resetInitialSubView}
                setView={setView}
                title="Advanced"
                workspace={memberEdge.node}
              >
                <div className="h-full overflow-y-auto pb-[env(safe-area-inset-bottom)]">
                  <Advanced workspace={memberEdge.node} />
                </div>
              </WorkspaceViewContent>
            )}

            {view === "billing" && memberEdge && workspaceID && allowBilling && (
              <WorkspaceViewContent
                resetInitialSubView={resetInitialSubView}
                setView={setView}
                title="Billing"
                workspace={memberEdge.node}
              >
                <div className="h-full overflow-y-auto pb-[env(safe-area-inset-bottom)]">
                  <Billing workspaceID={workspaceID} />
                </div>
              </WorkspaceViewContent>
            )}
          </>
        )
      }
      menu={
        <div
          className={tw("flex flex-col relative h-full w-full", {
            "pointer-events-none md:pointer-events-auto": view,
          })}
        >
          <div
            className={tw(
              "border-b-1 flex items-center h-56 mb-16 md:mb-0 px-16",
              "border-border-container justify-center text-headline-bold text-text-primary",
              "md:border-transparent md:justify-start md:text-footnote-bold md:text-text-secondary"
            )}
          >
            <button
              className="hidden md:flex items-center"
              onClick={() => {
                resetInitialSubView();
                setParentView("profile");
              }}
              type="button"
            >
              <Icon icon="ChevronLeft" size={20} />
              All settings
            </button>
            <button
              className={tw(
                "md:hidden",
                "absolute left-0 h-44 pr-8 pl-16 z-1 transition-transform"
              )}
              onClick={() => setParentView(undefined)}
            >
              <Icon icon="ChevronLeft" size={24} />
            </button>
            <span className="md:hidden">Workspace settings</span>
          </div>

          <div className="grow min-h-0 overflow-x-hidden relative">
            <div className="bg-background-body md:bg-background-app px-12 md:px-8 select-none">
              <ProfileRow margin="mb-12" workspace={memberEdge?.node} />

              <SidebarItem
                icon="User"
                isActive={view === "members"}
                onClick={() => setView("members")}
              >
                Members
              </SidebarItem>
              <SidebarItem
                icon="Groups"
                isActive={view === "groups"}
                onClick={() => setView("groups")}
              >
                Groups
              </SidebarItem>
              <SidebarItem
                icon="ExternalAccount"
                isActive={view === "apps"}
                onClick={() => setView("apps")}
              >
                Apps
              </SidebarItem>

              {isAdmin && (
                <>
                  <SidebarItem
                    icon="Settings"
                    isActive={view === "settings"}
                    onClick={() => setView("settings")}
                  >
                    Settings
                  </SidebarItem>
                  <SidebarItem
                    icon="Grid"
                    isActive={view === "advanced"}
                    onClick={() => setView("advanced")}
                  >
                    Advanced
                  </SidebarItem>
                </>
              )}

              {allowBilling && (
                <SidebarItem
                  icon="Read"
                  isActive={view === "billing"}
                  onClick={() => setView("billing")}
                >
                  Billing
                </SidebarItem>
              )}
            </div>
          </div>
        </div>
      }
    />
  );
};

export default WorkspaceSettings;
