import {
  ComponentProps,
  Dispatch,
  ElementRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router";
import { useDebouncedCallback } from "use-debounce";

import { Thread, User } from "@utility-types";
import { Button } from "components/design-system/Button";
import { Form } from "components/design-system/Forms";
import { MessageEditor } from "components/MessageEditor";
import { HistoryState } from "components/Navigation/HistoryState";
import { currentPathWithoutDrawer, routeToThread } from "components/routing/utils";
import { useValidateMessage } from "components/thread/hooks";
import { useSendMessage } from "components/threads/ThreadCompose/hooks";
import { Action, formToInput } from "components/threads/ThreadCompose/SendMessageReducer";
import ThreadComposeEditor from "components/threads/ThreadCompose/ThreadComposeEditor";
import ThreadPromptSuggestions from "components/threads/ThreadCompose/ThreadPromptSuggestions";
import { DraftForm } from "components/threads/ThreadCompose/types";
import useReadOnlyEditorState from "hooks/editor/useReadOnlyEditorState";
import { useGlueAIBot } from "hooks/glueAI/useGlueAIBot";
import useComponentMounted from "hooks/useComponentMounted";
import useAppStateStore from "store/useAppStateStore";
import tw from "utils/tw";

import useAIComposeDraft from "../hooks/useAIComposeDraft";
import useLlmModel from "../hooks/useLlmModel";

import AIComposeHeader from "./AIComposeHeader";

type FormData = DraftForm & {
  chatModel: string;
};

type Props = {
  glueAIBot?: User;
  isModal?: boolean;
  secondaryPane?: boolean;
} & Parameters<typeof useSendMessage>[0];

const MetadataWatcher = ({
  dispatch,
  metadata,
}: { dispatch: Dispatch<Action>; metadata: DraftForm["metadata"] }) => {
  const { watch } = useFormContext();
  useEffect(() => {
    const { unsubscribe } = watch(({ chatModel }) => {
      const newMetadata = { ...metadata, aiSettings: { chatModel } };
      dispatch({ type: "change", draftForm: { metadata: newMetadata } });
    });
    return () => unsubscribe();
  }, [dispatch, metadata, watch]);

  return null;
};

const AIComposeInner = ({ glueAIBot, initialDraft, isModal, secondaryPane }: Props) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  const history = useHistory<HistoryState>();

  const editor = useRef<ElementRef<typeof MessageEditor> | null>(null);
  const formRef = useRef<HTMLFormElement | null>(null);
  const { validateMessageToGlueAI } = useValidateMessage();

  useReadOnlyEditorState(editor);

  const { aiDraft, removeDraft } = useAIComposeDraft();
  const { defaultModel } = useLlmModel();

  const handleClose = useCallback(() => {
    if (secondaryPane) {
      history.push(currentPathWithoutDrawer());
    }
  }, [history, secondaryPane]);

  const onFinish = useCallback(
    (result?: Thread) => {
      if (!result) {
        handleClose();
        return;
      }

      removeDraft();

      history.replace(
        routeToThread({
          threadID: result.id,
          to: secondaryPane ? "secondary" : "primary",
        })
      );
    },
    [handleClose, history, removeDraft, secondaryPane]
  );

  const { compose, dispatch, sendDraft } = useSendMessage({
    initialDraft: {
      ...initialDraft,
      message: {
        text: aiDraft?.message.text ?? "",
        attachments: aiDraft?.message.attachments ?? [],
      },
      metadata: aiDraft?.metadata,
      recipients: glueAIBot ? [glueAIBot] : [],
      subject: aiDraft?.subject ?? "",
    },
    onFinish,
  });

  const isMounted = useComponentMounted();

  const handleSaveDraft = useCallback(() => {
    // save draft to local storage so that it can be restored at a later time;
    // only do this on desktop.
    if (!breakpointMD || !isMounted.current) return;
    useAIComposeDraft.setState({
      aiDraft: compose.draftForm,
    });
  }, [breakpointMD, compose.draftForm, isMounted]);

  const debouncedSaveDraft = useDebouncedCallback(handleSaveDraft, 500, {
    leading: true,
    trailing: true,
  });

  useEffect(() => {
    debouncedSaveDraft();
  }, [debouncedSaveDraft, handleSaveDraft]);

  // cancel debounce when component unmounts
  useEffect(() => () => debouncedSaveDraft.cancel(), [debouncedSaveDraft]);

  // Callbacks

  const onSubjectChange = useCallback(
    (subject: string) => {
      dispatch({ type: "change", draftForm: { subject } });
    },
    [dispatch]
  );

  const onMessageChange = useCallback(() => {
    if (!editor.current) return;
    const { text, attachments } = editor.current.getMessage();
    const appUnfurlSetups = editor.current.getAppUnfurlSetups();
    dispatch({
      type: "change",
      draftForm: { message: { text, attachments }, appUnfurlSetups },
    });
  }, [dispatch]);

  // Disable submit button when pending or invalid content

  const readOnly = !!compose.pending;

  const submitDisabled = useMemo(
    () =>
      readOnly ||
      !validateMessageToGlueAI(
        formToInput({
          ...compose.draftForm,
        }),
        "send",
        false
      ),
    [compose.draftForm, readOnly, validateMessageToGlueAI]
  );

  useEffect(() => {
    editor.current?.setReadOnly(readOnly);
    editor.current?.setIsProcessing(submitDisabled);
  }, [readOnly, submitDisabled]);

  const defaultSubject = aiDraft?.subject ?? compose.draftForm?.subject ?? "";

  return (
    <Form<FormData>
      className={tw("relative flex flex-col px-0 h-full w-full", "md:min-h-[50%] md:max-h-[100%]")}
      formRef={formRef}
      useFormProps={{
        defaultValues: {
          chatModel: defaultModel,
          recipients: [glueAIBot],
          subject: defaultSubject,
        },
      }}
    >
      <AIComposeHeader
        compose={{
          ...compose,
          draftID: undefined,
        }}
        isModal={isModal}
        onSubjectChange={onSubjectChange}
        onClose={secondaryPane ? handleClose : undefined}
        readOnly={readOnly}
      />
      <MetadataWatcher dispatch={dispatch} metadata={compose.draftForm.metadata} />

      <ThreadComposeEditor
        accessory={
          !editor.current?.hasText() && (
            <ThreadPromptSuggestions
              onChoose={({ text, files }) => {
                editor.current?.setMessage({
                  text,
                  attachments: [],
                });
                if (files) {
                  editor.current?.addAttachments(files);
                }
              }}
            />
          )
        }
        autoFocus
        compose={{
          ...compose,
          draftID: undefined,
        }}
        editor={editor}
        onChange={onMessageChange}
        sendDraft={sendDraft}
      />

      <div className="hidden md:flex flex-row items-center justify-end px-20 py-16 border-t-border-container select-none touch-none">
        <Button
          buttonStyle="primary"
          icon="Send"
          iconSize={20}
          type="submit"
          disabled={submitDisabled}
          onClick={sendDraft}
        >
          Send
        </Button>
      </div>
    </Form>
  );
};

const AICompose: React.FC<Props> = (props: ComponentProps<typeof AICompose>) => {
  const glueAIBot = useGlueAIBot();

  // ensures the composer is never initialized without the glueAIBot user;
  // typically only an issue when the browser view is reloaded while the composer is open
  if (!glueAIBot) return null;

  return <AIComposeInner glueAIBot={glueAIBot} {...props} />;
};

export default AICompose;
