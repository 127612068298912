import { WorkspaceAppEdge } from "@utility-types";
import useConfigureApp from "components/Masonry/useConfigureApp";
import {
  AppFieldsFragment,
  useWorkspaceAppConfigLazyQuery,
  useWorkspacesAppsQuery,
} from "generated/graphql";
import { useSnackbar } from "providers/SnackbarProvider";
import useModalStore from "store/useModalStore";
import { isNativeMac } from "utils/platform";

import { isWebhook } from "./utils/isWebhook";

type ConfigureAppsProps = {
  app: AppFieldsFragment;
  closeModalProp?: boolean;
  configurableID: string;
  workspaceID: string;
};

const useConfigureApps = () => {
  const { configure } = useConfigureApp();
  const [workspaceAppConfigQuery] = useWorkspaceAppConfigLazyQuery();
  const { data: workspaceApps } = useWorkspacesAppsQuery();

  const { openSnackbar } = useSnackbar();

  const { closeModal } = useModalStore(({ closeModal }) => ({
    closeModal,
  }));

  const configureApp = ({
    app,
    closeModalProp = true,
    configurableID,
    workspaceID,
  }: ConfigureAppsProps) => {
    const path = (() => {
      const prefix = configurableID.slice(0, 4);
      switch (prefix) {
        case "thr_":
          return app.configureThreadPath;
        case "grp_":
          return app.configureGroupPath;
        case "wks_":
          return app.configureWorkspacePath;
        default:
          return null;
      }
    })();

    if (path) {
      configure(app.id, path, workspaceID, configurableID);
      return;
    }

    workspaceAppConfigQuery({
      fetchPolicy: "no-cache",
      variables: { appID: app.id, configurableID, workspaceID },
    }).then(async ({ data }) => {
      if (!data?.workspaceAppConfig) return;

      const { token, url } = data.workspaceAppConfig;

      if (!token) {
        window.open(url, "_blank");
        closeModalProp && closeModal();
        return;
      }

      if (isNativeMac()) {
        const parsedURL = new URL(url);
        parsedURL.searchParams.append("token", token);
        window.open(parsedURL.toString(), "_blank");
        return;
      }

      await fetch(url, {
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
      })
        .then(response => {
          response.json().then(r => {
            window.open(r.url, "_blank");
          });
        })
        .catch(_ => openSnackbar("server", undefined, 5000));

      closeModalProp && closeModal();
    });
  };

  const workspaceEdges = workspaceApps?.workspaces.edges;

  const getAppsAvailable = (list: WorkspaceAppEdge[]) =>
    workspaceEdges?.flatMap(w =>
      w.node.apps.edges.filter(
        app =>
          !list.find(l => l.node.id === app.node.id && l.workspaceID === app.workspaceID) &&
          !isWebhook(app.node)
      )
    );

  const editApp = (
    app: AppFieldsFragment,
    configurableID?: string,
    workspaceID?: string | null
  ) => {
    if (!configurableID || !workspaceID) return;

    configureApp({
      app,
      closeModalProp: false,
      configurableID,
      workspaceID,
    });
  };

  return {
    configureApp,
    editApp,
    getAppsAvailable,
  };
};

export default useConfigureApps;
