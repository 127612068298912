import { nodeAs } from "@utility-types";
import Avatar from "components/design-system/Avatar/Avatar";
import { Skeleton } from "components/Skeleton";
import { useFetchWorkspaceOrPreviewEdgeQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";

import IdentityBadge from "../IdentityBadge/IdentityBadge";

export default function WorkspaceItem({ workspaceID }: { workspaceID: string }) {
  const { authData, authReady } = useAuthData();
  const { data } = useFetchWorkspaceOrPreviewEdgeQuery({
    fetchPolicy: authReady ? "cache-first" : "cache-only",
    skip: !(workspaceID && authData),
    variables: {
      id: `${workspaceID}-${authData?.me.id}`,
    },
  });

  const workspaceEdge = nodeAs(data?.node, ["WorkspaceEdge", "WorkspacePreviewEdge"]);

  const memberCount = workspaceEdge?.node.members.totalCount;
  const memberLabel = memberCount === 1 ? "member" : "members";

  return (
    <div className="flex items-center h-40 overflow-hidden">
      <Avatar
        avatarURL={workspaceEdge?.node.avatarURL}
        name={workspaceEdge?.node.name}
        margin="mr-8"
        rounded="rounded-md"
        size="medium"
      />
      <div className="overflow-hidden">
        <div className="text-subhead-bold truncate select-text">
          {workspaceEdge ? workspaceEdge.node.name : <Skeleton width="80px" height="32px" />}
        </div>
        <div className="flex">
          <div className="text-footnote text-text-subtle flex overflow-hidden">
            <IdentityBadge recipient={workspaceEdge?.node} />
            <span className="mx-4">·</span>
            {memberCount && (
              <>
                <span className="mr-4">{memberCount}</span>
                <span className="truncate mr-4">{memberLabel}</span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
