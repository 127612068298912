import {
  Placement,
  autoUpdate,
  flip,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
} from "@floating-ui/react";
import { useRef, useState, type AriaRole } from "react";

type Props = {
  anchorRole?: AriaRole;
  placement?: Placement;
  strategy?: "absolute" | "fixed"; // use "fixed" when rendering floating element in a portal
  loop?: boolean;
  keyboardHandlers?: boolean;
};

/**
 * Hook that provides a set of utilities to handle keyboard navigation in floating lists.
 * For more examples or implementation details, see the Floating UI docs:
 * https://floating-ui.com/docs/useListNavigation
 */
const useFloatingList = ({
  anchorRole = "button",
  placement,
  strategy,
  loop = true,
  keyboardHandlers = true,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const listRef = useRef<(HTMLElement | null)[]>([]);

  const { context, floatingStyles, refs } = useFloating({
    onOpenChange: setIsOpen,
    open: isOpen,
    placement,
    strategy,
    whileElementsMounted: autoUpdate,
    middleware: [
      flip({
        crossAxis: false,
      }),
      shift(),
    ],
  });

  const listNavigation = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    focusItemOnOpen: true,
    loop,
  });

  const click = useClick(context, {
    enabled: true,
    keyboardHandlers,
  });

  const dismiss = useDismiss(context, { enabled: isOpen });
  const role = useRole(context, { role: "listbox" });

  const { getFloatingProps, getReferenceProps, getItemProps } = useInteractions([
    listNavigation,
    click,
    dismiss,
    role,
  ]);

  return {
    anchorProps: getReferenceProps({
      ref: refs.setReference,
      role: anchorRole,
    }),
    floatingProps: getFloatingProps({
      ref: refs.setFloating,
      style: floatingStyles,
    }),
    getItemProps,
    activeIndex,
    listRef,
    isOpen,
    setIsOpen,
  };
};

export default useFloatingList;
