import { forwardRef, useImperativeHandle, useRef, useState } from "react";

import { Button } from "components/design-system/Button";
import { Form } from "components/design-system/Forms";
import { ModalProps } from "components/ModalKit/Modal";
import { Footer, Header, Main } from "components/ModalKit/Parts";
import { StandardModal } from "components/Modals";
import useModalStore from "store/useModalStore";

import BlockComponent, { BlockInteractionHandlers } from "./BlockComponent";
import { ModalBlock } from "./blockTypes";

type BlockModalProps = {
  block: ModalBlock;
  surfaceKey: string;
  handlers: BlockInteractionHandlers;
  onSubmit: () => void;
  onClose: () => void;
};

export interface SurfaceUpdate {
  update: (data: BlockModalProps) => void;
}

const BlockModal = forwardRef<SurfaceUpdate, BlockModalProps>(
  (props: ModalProps & BlockModalProps, ref) => {
    const [surface, setSurface] = useState(props.block);
    const [surfaceKey, setSurfaceKey] = useState(props.surfaceKey);
    const handlersRef = useRef(props.handlers);
    const onSubmitRef = useRef(props.onSubmit);
    const onCloseRef = useRef(props.onClose);

    useImperativeHandle(
      ref,
      () => {
        return {
          update(props: BlockModalProps) {
            setSurface(props.block);
            setSurfaceKey(props.surfaceKey);
            handlersRef.current = props.handlers;
            onSubmitRef.current = props.onSubmit;
            onCloseRef.current = props.onClose;
          },
        };
      },
      [setSurface, handlersRef, onSubmitRef]
    );

    const { closeModal } = useModalStore(({ closeModal, openModal }) => ({
      closeModal,
      openModal,
    }));

    if (surface.type !== "modal") {
      throw new Error(`Unexpected surface type ${surface.type}`);
    }

    const handlers: BlockInteractionHandlers = {
      onLink: path => handlersRef.current.onLink(path),
      onAction: (block, value) => handlersRef.current.onAction(block, value),
      onOptionsLoad: (block, value) => handlersRef.current.onOptionsLoad(block, value),
      onValueChanged: (block, value) => handlersRef.current.onValueChanged(block, value),
    };

    const onSubmit = () => {
      onSubmitRef.current();
    };

    const handleCloseModal = () => {
      closeModal(props.modalId);
    };

    const afterClose = () => {
      onCloseRef.current();
    };

    return (
      <Form className="contents" onSubmit={onSubmit}>
        <StandardModal
          afterClose={afterClose}
          contentHandlesSafeArea={false}
          header={
            <Header mobileCtaLabel={surface.submit?.text} variant="bordered">
              <h3 className="m-0">{surface.title?.text}</h3>
            </Header>
          }
          {...props}
        >
          <Main key={surfaceKey} className="flex flex-col grow px-16 py-8 md:px-32">
            {surface.blocks.map(block => (
              <BlockComponent key={block.key} block={block} handlers={handlers} />
            ))}
          </Main>

          <Footer className="hidden md:flex">
            <div className="flex space-x-16">
              <Button
                buttonStyle="simpleSecondary"
                buttonType="none"
                onClick={handleCloseModal}
                type="button"
              >
                {surface.close?.text || "Close"}
              </Button>
              {surface.submit ? (
                <Button buttonStyle="primary" type="submit">
                  {surface.submit.text}
                </Button>
              ) : undefined}
            </div>
          </Footer>
        </StandardModal>
      </Form>
    );
  }
);

export default BlockModal;
