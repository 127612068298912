import { ReactNode, useCallback, useEffect } from "react";
import { useHistory } from "react-router";

import { Button } from "components/design-system/Button";
import useFormSubmit from "components/design-system/Forms/hooks/useFormSubmit";
import { Footer, Header, Main } from "components/ModalKit";
import { ModalProps } from "components/ModalKit/Modal";
import useAppStateStore from "store/useAppStateStore";
import useModalStore from "store/useModalStore";
import tw from "utils/tw";

import { StandardModal } from "./StandardModal";

type Props = {
  header: string;
  content?: ReactNode;
  confirmLabel?: string;
  isDestructive?: boolean;
  onConfirm: () => Promise<unknown>;
} & ModalProps;

/**
 * A modal that is intended for longer-form confirmation of actions when compared to the `ConfirmationAlert` modal. It should be
 * used for confirming actions that require more detailed explanation of the action to the user.
 */
export const ConfirmationModal = ({
  confirmLabel,
  header,
  isDestructive,
  content,
  onConfirm,
  ...props
}: Props) => {
  const history = useHistory();
  const { closeModal } = useModalStore(({ closeModal }) => ({
    closeModal,
  }));
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  const handleCloseModal = useCallback(
    () => closeModal(props.modalId),
    [closeModal, props.modalId]
  );

  // if location changes, close the modal.
  useEffect(
    () => history.listen(handleCloseModal),
    [closeModal, handleCloseModal, history, props.modalId]
  );

  const { handleFormSubmit, submitting } = useFormSubmit(() => onConfirm().then(handleCloseModal));

  return (
    <StandardModal
      closeButton
      closeButtonPosition="right"
      containerScrolling={false}
      contentClassName="md:w-[640px] md:max-w-[calc(100%_-_30px)] rounded-lg"
      heightAuto
      {...props}
    >
      <Header
        variant="bordered"
        textAlign={breakpointMD ? "left" : "center"}
        className={tw("md:px-32 md:!pr-20", {
          "!px-16": !breakpointMD,
        })}
      >
        <h3 className="my-0 text-headline-bold">{header}</h3>
      </Header>
      <Main
        className={tw("py-20 px-32", {
          "min-h-300": !breakpointMD,
        })}
      >
        {content}
      </Main>
      <Footer
        variant="bordered"
        className="!px-20"
        flexboxClassName={
          breakpointMD
            ? "flex-row justify-end gap-8"
            : "flex-col-reverse gap-16 w-full items-center"
        }
      >
        <Button
          buttonStyle="simpleSecondary"
          disabled={submitting}
          onClick={handleCloseModal}
          className={!breakpointMD ? "w-full justify-center" : undefined}
        >
          Cancel
        </Button>
        <Button
          buttonStyle={isDestructive ? "primaryDestructive" : "primary"}
          disabled={submitting}
          onClick={handleFormSubmit}
          className={!breakpointMD ? "w-full justify-center" : undefined}
        >
          {confirmLabel}
        </Button>
      </Footer>
    </StandardModal>
  );
};
