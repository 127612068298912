import { ModalProps } from "stream-chat-react";

import { FullScreenOverlay } from "components/Modals";
import tw from "utils/tw";

type MediaModalProps = {
  children: React.ReactNode;
};

/**
 * @summary A generic modal for use as audio, image and video modals.
 */

const MediaModal = ({ children, ...props }: MediaModalProps & Partial<ModalProps>) => (
  <FullScreenOverlay
    closeButtonClassName={tw(
      "!text-icon-action-inverse drop-shadow opacity-75 hover:opacity-100",
      "absolute top-8 right-8 w-40 h-44 z-1",
      "md:top-16 md:size-24 md:right-16"
    )}
    {...props}
  >
    {children}
  </FullScreenOverlay>
);

export default MediaModal;
