import { ComponentProps, useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";

import { Mailbox, Recipient, nodeAs } from "@utility-types";
import { usePartitionState } from "components/routing/RoutingPartition";
import { currentPathWithoutDrawer } from "components/routing/utils";
import AddMentionToThread from "components/thread/AddMentionToThread/AddMentionToThread";
import NotInThread from "components/thread/NotInThread/NotInThread";
import ThreadChannel from "components/thread/ThreadView/components/ThreadChannel";
import { ThreadViewProvider } from "components/thread/ThreadView/provider/ThreadViewProvider";
import { useSendMessage } from "components/threads/ThreadCompose/hooks";
import ThreadReply from "components/threads/ThreadReply";
import AIThreadHeader from "components/views/ai/AIViews/AIThreadHeader";
import { Addable, useFetchThreadEdgeSimpleQuery } from "generated/graphql";
import useFetchThreadReplyTo from "hooks/thread/useFetchThreadReplyTo";
import useAuthData from "hooks/useAuthData";
import useHistoryItem from "hooks/useHistoryItem";
import useMemberEdge from "hooks/useMemberEdge";
import useAppStateStore from "store/useAppStateStore";

type Props = Omit<ComponentProps<typeof ThreadViewProvider>, "mailbox" | "threadID"> & {
  headerType?: "none" | "simple" | "full";
  onSubmit?: () => void;
  secondaryPane?: boolean;
  setFocusedThreadId?: (id: string) => void;
  threadID?: string;
};

export const AIThreadViewWithoutFocus = ({
  headerType = "full",
  messageID,
  onSubmit,
  recipientID,
  secondaryPane,
  setFocusedThreadId,
  threadID,
}: Props) => {
  const { authData, authReady } = useAuthData();
  const history = useHistory();

  const { data, loading } = useFetchThreadEdgeSimpleQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    skip: !authData?.me.id || !threadID,
    variables: { id: `${threadID}-${authData?.me.id}` },
  });

  const threadEdge = nodeAs(data?.node, ["ThreadEdge", "ThreadPreviewEdge"]);
  const { memberEdge, previewEdge } = useMemberEdge(threadEdge);

  const { isPersistentChat } = memberEdge?.node || {};

  const replyTo = useFetchThreadReplyTo(memberEdge?.node);
  const lastMessageID = memberEdge?.node.lastMessage?.id;

  const [lastMention, setLastMention] = useState<Recipient>();

  useEffect(() => {
    setLastMention(undefined);
  }, [memberEdge]);

  const ref = useRef<HTMLDivElement>(null);
  const [isActiveThread, setIsActiveThread] = useState(true);

  useEffect(() => {
    if (!ref.current) return;
    const el = ref.current;
    const pointerHandler = (e: PointerEvent) => {
      // we maybe want to check what was clicked?
      e && setIsActiveThread(true);
    };

    const focusHandler = () => setIsActiveThread(true);

    el.addEventListener("pointerdown", pointerHandler);
    el.addEventListener("focusin", focusHandler);

    return () => {
      el.removeEventListener("focusin", focusHandler);
      el.removeEventListener("pointerdown", pointerHandler);
    };
  }, [isActiveThread, threadID]);

  useEffect(() => {
    if (!isActiveThread) return;
    setFocusedThreadId?.(threadID ?? "");
  }, [isActiveThread, setFocusedThreadId, threadID]);

  useEffect(
    () =>
      useAppStateStore.subscribe(
        ({ activeThreadId }) => activeThreadId !== threadID && setIsActiveThread(false)
      ),
    [threadID]
  );

  const onThreadClose = secondaryPane ? () => history.push(currentPathWithoutDrawer()) : undefined;

  const threadTitle = threadEdge?.node.subject;

  useHistoryItem({
    title: threadTitle,
  });

  const { compose, dispatch } = useSendMessage({
    initialDraft: {
      recipients: memberEdge?.node?.recipients.edges.map(e => e.node),
      subject: memberEdge?.node?.subject,
    },
  });

  const onAddableChange = useCallback(
    (recipientsAddable: Addable) => {
      dispatch({ type: "change", draftForm: { recipientsAddable } });
    },
    [dispatch]
  );

  const onRecipientsChange = useCallback(
    (recipients: Recipient[]) => {
      dispatch({ type: "change", draftForm: { recipients } });
      setLastMention(undefined);
    },
    [dispatch]
  );

  return (
    <div ref={ref} className="flex flex-col grow h-full w-full">
      {headerType !== "none" ? (
        <AIThreadHeader
          compose={{
            ...compose,
            draftID: undefined,
          }}
          onClose={onThreadClose}
          onAddableChange={onAddableChange}
          onRecipientsChange={onRecipientsChange}
          secondaryPane={secondaryPane}
          threadEdge={threadEdge}
        />
      ) : null}

      {threadID ? (
        previewEdge ? ( // don't show "not in thread" until determined
          <NotInThread threadID={threadID} />
        ) : memberEdge || loading ? (
          <ThreadViewProvider
            isMessageable
            lastMessageID={lastMessageID}
            mailbox={Mailbox.Ai}
            messageID={messageID}
            recipientID={recipientID}
            recipientRole={memberEdge?.recipientRole}
            replyTo={replyTo}
            threadStartedAt={memberEdge?.node.firstMessage?.createdAt}
            threadPane={secondaryPane ? "secondary" : "primary"}
            threadID={threadID}
            threadWorkspaceID={memberEdge?.node.workspaceID ?? undefined}
            isPersistentChat={isPersistentChat}
          >
            <ThreadChannel>
              <AddMentionToThread mention={lastMention} threadEdge={memberEdge} />
              <ThreadReply onMention={setLastMention} onSubmit={onSubmit} />
            </ThreadChannel>
          </ThreadViewProvider>
        ) : null
      ) : null}
    </div>
  );
};

/**
 * use the default export in most cases;
 * use the named export (above) when needing to use the Thread outside of the partition context, such as in a modal.
 */
const AIThreadView = (props: Props) => {
  const { setFocusedThreadId } = usePartitionState(({ setFocusedThreadId }) => ({
    setFocusedThreadId,
  }));

  return <AIThreadViewWithoutFocus setFocusedThreadId={setFocusedThreadId} {...props} />;
};

export default AIThreadView;
