import { ComponentProps, PropsWithChildren, useState } from "react";
import { Link } from "react-router-dom";

import { Recipient } from "@utility-types";
import Avatar from "components/design-system/Avatar/Avatar";
import { Button } from "components/design-system/Button";
import Dropdown from "components/design-system/FloatingUi/Dropdown";
import { Icon } from "components/design-system/icons";
import IdentityBadge from "components/design-system/ui/IdentityBadge/IdentityBadge";
import useHistoryStore, { isInboxChat } from "store/useHistoryStore";
import { isNative } from "utils/platform";
import tw from "utils/tw";

function HistoryItem({ children, path }: PropsWithChildren<{ path: string }>) {
  return (
    <li className="hover:bg-background-list-hover rounded-md">
      <Link className="px-12 py-6 block" to={path}>
        {children}
      </Link>
    </li>
  );
}

function GroupItemElement({
  emojiIcon: emoji,
  fallBackIcon,
  recipient,
  label,
  name,
}: {
  emojiIcon?: string | null;
  fallBackIcon: JSX.Element;
  label?: string;
  name: string;
  recipient?: Recipient;
}) {
  return (
    <div className="flex justify-between overflow-hidden items-center">
      <span className="flex items-center truncate">
        <span className="text-icon-primary">
          {emoji ? (
            <span
              className={tw(
                "flex justify-center w-20 min-w-[20px]",
                isNative() ? "text-base" : "text-lg"
              )}
            >
              {emoji}
            </span>
          ) : (
            fallBackIcon
          )}
        </span>
        <span className="px-8 font-semibold truncate">{name}</span>
        {recipient && <IdentityBadge recipient={recipient} />}
      </span>
      <span className="text-subhead text-text-subtle leading-5">{label}</span>
    </div>
  );
}

function UserItemElement({
  avatarURL,
  recipient,
  name,
}: {
  avatarURL?: string | null;
  recipient?: Recipient;
  name: string;
}) {
  return (
    <div className="flex overflow-hidden items-center">
      <span>
        <Avatar avatarURL={avatarURL} name={name} size="x-small" rounded />
      </span>
      <span className="px-8 font-semibold truncate">{name}</span>
      {recipient && <IdentityBadge recipient={recipient} />}
    </div>
  );
}

function ThreadItemElement({
  icon,
  title,
}: {
  icon?: ComponentProps<typeof Icon>["icon"] | undefined;
  title: string;
}) {
  return (
    <div className="flex overflow-hidden items-center">
      <span className="flex w-20 text-icon-primary justify-center">
        <Icon className="text-icon-secondary" icon={icon ?? "Edit"} size={20} />
      </span>
      <span className="px-8 font-semibold truncate">{title}</span>
    </div>
  );
}

function List({ children, setOpen }: PropsWithChildren & { setOpen: (open: boolean) => void }) {
  return (
    <ul className="p-8" onClick={() => setOpen(false)}>
      <li className="px-12 py-6">
        <span className="text-text-subtle font-semibold text-base leading-5">Recent</span>
      </li>
      {children}
    </ul>
  );
}

const HistoryButton = () => {
  const state = useHistoryStore();
  const backStack = state.backStack.slice();
  const forwardStack = state.forwardStack.slice().reverse();
  const currentItem = backStack.splice(0, 1);

  const items = ([...currentItem, ...forwardStack, ...backStack] as typeof state.backStack).map(
    i => {
      if (i.primaryType === "inbox") {
        if (!isInboxChat(i)) {
          return (
            <HistoryItem key={i.path} path={i.path}>
              <ThreadItemElement icon={i.icon ?? "Thread"} title={i.title} />
            </HistoryItem>
          );
        }
        if (i.chatType === "user") {
          return (
            <HistoryItem key={i.path} path={i.path}>
              <UserItemElement avatarURL={i.avatarURL} {...i} />
            </HistoryItem>
          );
        }

        return (
          <HistoryItem key={i.path} path={i.path}>
            <GroupItemElement
              emojiIcon={i.emoji}
              fallBackIcon={<Icon className="text-icon-secondary" icon="ChatRounded" size={20} />}
              {...i}
            />
          </HistoryItem>
        );
      }

      if (i.primaryType === "dms") {
        return (
          <HistoryItem key={i.path} path={i.path}>
            <UserItemElement avatarURL={i.avatarURL} {...i} />
          </HistoryItem>
        );
      }

      if (i.primaryType === "groups") {
        if (i.isExplore) {
          return (
            <HistoryItem key={i.path} path={i.path}>
              <GroupItemElement
                fallBackIcon={
                  <img
                    alt="Explore option avatar"
                    className="w-20 h-20 rounded-md"
                    src={i.imageURL || ""}
                  />
                }
                label={i.secondaryType}
                {...i}
              />
            </HistoryItem>
          );
        }

        return (
          <HistoryItem key={i.path} path={i.path}>
            <GroupItemElement
              emojiIcon={i.emoji}
              fallBackIcon={<Icon className="text-icon-secondary" icon="Thread" size={20} />}
              label={i.secondaryType}
              {...i}
            />
          </HistoryItem>
        );
      }

      if (i.primaryType === "ai" || i.primaryType === "threads") {
        return (
          <HistoryItem key={i.path} path={i.path}>
            <div className="flex overflow-hidden">
              <ThreadItemElement icon={i.icon} title={i.title} />
            </div>
          </HistoryItem>
        );
      }
    }
  );

  const [isOpen, setOpen] = useState(true);

  return (
    <Dropdown
      className="w-400 rounded-lg text-text-primary !shadow-level2"
      content={<List setOpen={setOpen}>{items}</List>}
      setOpen={setOpen}
      disableFlip
    >
      <span>
        <Button
          buttonStyle="none"
          className={tw(
            "ml-[9px]",
            items.length
              ? "text-icon-action-inverse-subtle"
              : "text-icon-action-inverse-subtle-disabled"
          )}
          disabled={!items.length}
          icon="Clock"
          iconSize={20}
          iconStroke={1.5}
          tooltip={isOpen ? undefined : "History"}
          type="button"
        />
      </span>
    </Dropdown>
  );
};

export default HistoryButton;
