import React from "react";
import { Blurhash } from "react-blurhash";

import { GlueFile, LinksCategory, nodeIs } from "@utility-types/graphql";
import { Icon } from "components/design-system/icons";
import { PhotoSwipeGallery, PhotoSwipeItem } from "components/PhotoSwipe";
import { defaultPhotoSwipeGalleryOptions } from "components/PhotoSwipe/PhotoSwipeGallery";
import { Skeleton } from "components/Skeleton";
import { cloneElementForSkeletons } from "components/Skeleton/Skeleton";
import { FileFieldsFragment } from "generated/graphql";
import useAppStateStore from "store/useAppStateStore";
import useModalStore from "store/useModalStore";
import fixDevApiUrl from "utils/fixDevApiUrl";
import glueImageURL from "utils/glueImageURL";
import tw from "utils/tw";

import useSharedList from "./hooks/useSharedList";
import AudioModal from "./Media/AudioModal";
import VideoModal from "./Media/VideoModal";
import styles from "./Media.module.css";
import SharedEmptyState from "./SharedEmptyState";
import SharedListHeader from "./SharedListHeader";
import { FiltersKeys, SharedFilters } from "./types";
import { parseImage } from "./utils/parseImage";

type ItemsType = GlueFile & { createdAt: string };

const Scrim = () => (
  <div className="absolute inset-0 bg-background-black/50 opacity-0 hover:opacity-30" />
);

const Audio = ({ file }: { file: FileFieldsFragment }) => {
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const audioURL = fixDevApiUrl(file.url);

  return (
    <div
      className="cursor-pointer relative before:content[''] before:block before:pt-[100%]"
      onClick={() => {
        openModal(<AudioModal url={audioURL} />);
      }}
    >
      <div className="absolute inset-0 flex items-center justify-center bg-background-overlay">
        <Icon
          className="drop-shadow text-icon-action-inverse"
          icon="Volume2"
          size={24}
          strokeWidth={2}
        />
      </div>

      <Scrim />
    </div>
  );
};

type ImageProps = {
  file: FileFieldsFragment;
};

export const Image = ({ file }: ImageProps) => {
  const {
    blurHash,
    height,
    imageAlt,
    imageSrc,
    imageResize,
    thumbnailHeight,
    thumbnailResize,
    thumbnailWidth,
    width,
  } = parseImage(file);

  return (
    <PhotoSwipeItem
      key={file.id}
      fullSizeURL={glueImageURL(imageSrc, imageResize, 2)}
      height={height}
      id={file.id}
      originalURL={imageSrc}
      thumbnail={glueImageURL(imageSrc, thumbnailResize, 2)}
      title={imageAlt}
      width={width}
    >
      {({ bindLongPress, open, ref }) => (
        <div
          className="cursor-pointer relative before:content[''] before:block before:pt-[100%]"
          {...bindLongPress}
          onClick={open}
        >
          {
            // API sent invalidate data in the past,
            // so validate proper length to avoid crash
            blurHash?.length === 54 ? (
              <Blurhash
                className="!absolute inset-0"
                hash={blurHash}
                height="100%"
                punch={1}
                resolutionX={32}
                resolutionY={32}
                width="100%"
              />
            ) : (
              <div className="!absolute inset-0 bg-background-secondary" />
            )
          }
          {imageSrc ? (
            <picture className="absolute inset-0">
              <source
                srcSet={`${glueImageURL(
                  imageSrc,
                  thumbnailResize
                )} 1x, ${glueImageURL(imageSrc, thumbnailResize, 2)} 2x`}
              />
              <img
                ref={ref}
                alt={imageAlt || ""}
                className="object-cover opacity-0 transition-opacity duration-300 w-full h-full"
                height={thumbnailHeight}
                loading="lazy"
                onLoad={({ currentTarget }) => currentTarget.classList.remove("opacity-0")}
                src={glueImageURL(imageSrc, thumbnailResize, 0)}
                width={thumbnailWidth}
              />
            </picture>
          ) : null}

          <Scrim />
        </div>
      )}
    </PhotoSwipeItem>
  );
};

const Video = ({ file }: { file: FileFieldsFragment }) => {
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const videoURL = fixDevApiUrl(file.url);

  return (
    <div
      className="cursor-pointer relative before:content[''] before:block before:pt-[100%]"
      onClick={() => {
        openModal(<VideoModal title={file.name} url={videoURL} />);
      }}
    >
      <div className="absolute inset-0 flex items-center justify-center bg-background-overlay">
        <Icon
          className="drop-shadow text-icon-action-inverse"
          icon="Play"
          size={24}
          strokeWidth={2}
        />
      </div>

      <Scrim />
    </div>
  );
};

export const Figure = ({ file }: { file: FileFieldsFragment }) => (
  <figure itemScope itemType="http://schema.org/ImageObject" key={file.id}>
    <div className="border border-border-container hover:border-border-container-hover relative rounded-lg overflow-hidden">
      {file.fileType === "audio" && <Audio file={file} />}
      {file.fileType === "image" && <Image file={file} />}
      {file.fileType === "video" && <Video file={file} />}
    </div>
  </figure>
);

const Media = ({
  filter,
  groupID,
  isWide,
  openDetailedView,
}: {
  filter: FiltersKeys;
  groupID: string;
  isWide: boolean;
  openDetailedView: () => void;
}) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  const { items, groupedItems, loadMoreRef, loading, isRecent, hasNextPage } = useSharedList({
    groupID,
    category: LinksCategory.Media,
    recentCount: breakpointMD ? 21 : 8,
    pageSize: 50,
    selectedFilter: filter,
  });

  if (filter !== SharedFilters.Media && filter !== SharedFilters.Recent) {
    return null;
  }

  const media = isRecent
    ? items?.filter((i): i is GlueFile => nodeIs(i, ["File"]))
    : groupedItems.flatMap(g => g.items.filter((i): i is ItemsType => nodeIs(i, ["File"])));

  if (!media?.length && !loading) {
    return !isRecent ? <SharedEmptyState filter={SharedFilters.Media} /> : null;
  }

  return (
    <PhotoSwipeGallery id="Media" options={defaultPhotoSwipeGalleryOptions}>
      <div
        className={tw(
          styles["media-grid-container"],
          "px-20 pb-4 bg-background-body shadow-level1 overflow-hidden",
          {
            "rounded-lg": isWide,
          }
        )}
      >
        {loading ? (
          <>
            <SharedListHeader name="Media" />
            <div className={styles["media-grid"]}>
              {cloneElementForSkeletons(<Skeleton height="80px" width="80px" />, 3)}
            </div>
          </>
        ) : isRecent ? (
          <>
            <SharedListHeader name="Media" onShowAll={isRecent ? openDetailedView : undefined} />
            <div className={tw(styles["media-grid"], styles["two-rows"])}>
              {media?.map((file, i) => (
                <Figure key={`${file.id}-${i}`} file={file} />
              ))}
            </div>
          </>
        ) : (
          groupedItems.map(({ group: name, items: files }) => (
            <React.Fragment key={name}>
              <SharedListHeader name={name} />
              <div className={styles["media-grid"]}>
                {files
                  .filter((i): i is ItemsType => nodeIs(i, ["File"]))
                  .map((file, i) => (
                    <Figure key={`${file.id}-${i}`} file={file} />
                  ))}
              </div>
            </React.Fragment>
          ))
        )}

        {hasNextPage && (
          <div ref={loadMoreRef} className={styles["media-grid"]}>
            {Array.from({ length: 10 }, (_, i) => i).map(n => (
              <figure key={n} itemScope itemType="http://schema.org/ImageObject">
                <div className="border border-border-container hover:border-border-container-hover relative rounded-lg overflow-hidden">
                  <div className="relative before:content[''] before:block before:pt-[100%]">
                    <Skeleton className="absolute inset-0 m-0" />
                  </div>
                </div>
              </figure>
            ))}
          </div>
        )}
      </div>
    </PhotoSwipeGallery>
  );
};

export default Media;
