import { useKeymap, useRemirrorContext } from "@remirror/react";
import { ComponentProps, useCallback, useState } from "react";

import { Button } from "components/design-system/Button";
import { Dropdown } from "components/design-system/FloatingUi/Dropdown";

import buttonStyles from "../buttonStyles";

import LinkSettings from "./LinkSettings";
import { getLinkData } from "./utils";

type Props = ComponentProps<typeof Button>;

const LinkButton = ({ disabled, icon, tooltip }: Props): JSX.Element => {
  const { getState, view } = useRemirrorContext();
  const [open, setOpen] = useState(false);

  const handleKeypress = useCallback(
    ({ next }: { next: () => boolean }) => {
      const selection = getState().selection;
      const { text: selectedText } = getLinkData(selection, view);

      if (!selectedText) return next();

      setOpen(true);
      return true;
    },
    [getState, view]
  );
  useKeymap("Mod-Shift-u", handleKeypress);

  return (
    <Dropdown
      content={<LinkSettings selection={getState().selection} />}
      offsetMain={10}
      open={open}
      placement="top"
      setOpen={setOpen}
    >
      <span>
        <Button
          buttonStyle="subtle"
          className={buttonStyles(open)}
          data-testid="open-insert-link-popup"
          disabled={disabled}
          icon={icon}
          iconSize={20}
          type="button"
          tooltip={tooltip}
        />
      </span>
    </Dropdown>
  );
};

export default LinkButton;
