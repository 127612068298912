import { FirebaseAuthentication } from "@gluegroups/capacitor-firebase-authentication";
import axios from "axios";
import {
  GoogleAuthProvider,
  OAuthProvider,
  signInWithCredential,
  signInWithPopup,
  updateProfile,
} from "firebase/auth";
import { Dispatch, useState } from "react";
import { useFormContext } from "react-hook-form";

import appleIcon from "assets/appleIcon.png";
import googleIcon from "assets/googleIcon-color.png";
import { Button } from "components/design-system/Button";
import { Form, TextInput } from "components/design-system/Forms";
import { FREE_TRIAL_DAYS } from "constants/free-trial-days";
import FirebaseProxy from "providers/AuthProvider/hooks/useFirebaseAuth/FirebaseProxy";
import NoAuthError from "providers/AuthProvider/NoAuthError";
import useAppStateStore from "store/useAppStateStore";
import useOnboardingStore from "store/useOnboardingStore";
import { isNative, isNativeIOS, isNativeMac } from "utils/platform";
import env from "utils/processEnv";
import { getSessionStorage } from "utils/sessionStorage";
import tw from "utils/tw";

import { Action } from "./Reducer";

const AppleSignInDismissedError = "com.apple.AuthenticationServices.AuthorizationError error 1001";
const GoogleSignInDismissedError = "user canceled the sign-in flow";

const AppleSignInError = "Sign in with Apple did not complete. Please try again.";
const GoogleSignInError = "Sign in with Google did not complete. Please try again.";

const SignInError = "Something went wrong. Please try again.";

const buttonStyles =
  "w-full py-6 justify-center border rounded-md !border-background-strong hover:!border-background-strong-hover bg-background-body";

const SignInWithGoogle = () => {
  const auth = FirebaseProxy.auth;
  const [error, setError] = useState<string>("");

  const handleSignIn = async () => {
    if (isNative()) {
      const result = await FirebaseAuthentication.signInWithGoogle({
        customParameters: [{ key: "prompt", value: "select_account" }],
        scopes: ["email"],
      }).catch(err => {
        setError(GoogleSignInError);

        if (err.message.indexOf(GoogleSignInDismissedError) > -1) {
          return { credential: null, user: null };
        }

        console.warn("Error: [nativeSignIn] -", err);
        throw err;
      });
      if (!result.credential) {
        return { credential: null, user: null };
      }

      const credential = GoogleAuthProvider.credential(result.credential.idToken);

      return signInWithCredential(auth, credential);
    }

    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: "select_account" });
    provider.addScope("email");

    signInWithPopup(auth, provider).catch(err => {
      setError(SignInError);
      console.warn("Error: [googleSignIn] -", err);
    });
  };

  return (
    <>
      <Button buttonStyle="none" className={tw("mb-8", buttonStyles)} onClick={handleSignIn}>
        <img alt="Google Logo" className="mr-10" height="24" src={googleIcon} width="24" />
        Sign in with Google
      </Button>
      {error && <p className="m-0 mb-16 text-sm text-accent-error">{error}</p>}
    </>
  );
};

const SignInWithApple = () => {
  const auth = FirebaseProxy.auth;
  const [error, setError] = useState<string>("");

  const handleSignIn = async () => {
    if (isNativeIOS() || isNativeMac()) {
      const result = await FirebaseAuthentication.signInWithApple().catch(err => {
        setError(AppleSignInError);

        if (err.message.indexOf(AppleSignInDismissedError) > -1) {
          return { credential: null, user: null };
        }

        console.warn("Error: [nativeSignIn] -", err);
        throw err;
      });
      if (!result.credential) {
        return { credential: null, user: null };
      }
      const credential = new OAuthProvider("apple.com").credential({
        idToken: result.credential.idToken,
        rawNonce: result.credential.nonce,
      });

      return signInWithCredential(auth, credential).then(credential => {
        if (!auth.currentUser) return credential;

        // Save the user's display name on first sign in
        // Solves: https://github.com/firebase/quickstart-ios/pull/1471
        // See also: https://github.com/capawesome-team/capacitor-firebase/pull/368 (not using yet)
        if (!auth.currentUser.displayName && result.user?.displayName) {
          return updateProfile(auth.currentUser, {
            displayName: result.user.displayName,
            photoURL: result.user.photoUrl,
          }).then(() => {
            const { user, onboarding } = useOnboardingStore.getState();
            if (auth.currentUser && onboarding) {
              useOnboardingStore.setState({
                user: {
                  name: user?.name || (auth.currentUser?.displayName ?? ""),
                  avatarURL: user?.avatarURL || auth.currentUser?.photoURL,
                },
              });
            }

            return credential;
          });
        }

        return credential;
      });
    }
  };

  return (
    <>
      <Button buttonStyle="none" className={buttonStyles} onClick={handleSignIn}>
        <img alt="Apple Logo" className="mr-10" height="24" src={appleIcon} width="24" />
        Sign in with Apple
      </Button>
      {error && <p className="m-0 mb-16 text-sm text-accent-error">{error}</p>}
    </>
  );
};

const SignInWithEmailSubmitButton = ({ disabled }: { disabled?: boolean }) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const { watch } = useFormContext<FormData>();
  const email = watch("email");

  return (
    <Button
      testId="sign-in-with-email-submit"
      className="w-full md:w-36 h-36 px-16 py-6 md:!px-6 justify-center mt-12 md:mt-0"
      disabled={disabled || !email?.length}
      icon={breakpointMD ? "ArrowRight" : undefined}
      iconSize={24}
      children={!breakpointMD ? "Continue with email" : undefined}
    />
  );
};

type FormData = {
  email: string;
};
const SignInWithEmail = ({
  email,
  dispatch,
}: Partial<FormData> & { dispatch: Dispatch<Action> }) => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | JSX.Element>("");

  const handleFormSubmit = (data: FormData) => {
    setSubmitting(true);
    axios
      .post(`${env.glueApiUrl}/auth/start`, {
        address: data.email,
        workspaceInviteLink: getSessionStorage("workspace-invite-link"),
      })
      .then(() => {
        dispatch({ type: "VerificationCode", email: data.email });
      })
      .catch(err => {
        if (axios.isAxiosError(err) && err.response?.status === 401) {
          setError(<NoAuthError />);
          setSubmitting(false);
          return;
        }

        setError(SignInError);
        setSubmitting(false);
        console.warn("Error: [startEmailSignIn] -", err);
      });
  };

  return (
    <Form<FormData>
      className="flex flex-col md:flex-row w-full relative"
      onSubmit={handleFormSubmit}
      useFormProps={{ defaultValues: { email: email ?? "" } }}
    >
      <TextInput<FormData>
        testId="sign-in-with-email-text-input"
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        className="border-border-container h-36"
        config={{
          pattern: {
            message: "Please enter a valid email.",
            value: /\S+@\S+\.\S+/,
          },
        }}
        disabled={submitting}
        error={error}
        name="email"
        placeholder="Work email"
        type="email"
        wrapperClassName="!mt-0 md:mr-11 grow"
        inlineError
      />
      <SignInWithEmailSubmitButton disabled={submitting} />
    </Form>
  );
};

const appleSignInEnabled = isNativeIOS() || isNativeMac();

const SignIn = ({ email, dispatch }: { email?: string; dispatch: Dispatch<Action> }) => {
  const params = new URL(window.location.href).searchParams;
  const intent = params.get("intent");
  return (
    <div className="flex flex-col md:justify-center mx-auto max-w-full w-400 text-left">
      <span className="text-title-3">Work chat that sticks.</span>
      <span className="text-headline text-text-secondary">
        {intent === "signup"
          ? `Create an account with your work email to start your ${FREE_TRIAL_DAYS}-day risk-free trial.`
          : `Sign in with your work email ${intent === "signin" ? "to continue" : "to get started"}.`}
      </span>
      <div className="mt-32">
        <SignInWithGoogle />
        {appleSignInEnabled && <SignInWithApple />}
      </div>
      <span className="mt-16 text-subhead text-text-subtle text-center">
        Or, sign in with email
      </span>
      <div className="mt-16">
        <SignInWithEmail email={email} dispatch={dispatch} />
      </div>
    </div>
  );
};

export default SignIn;
